.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Memodifikasi tombol untuk bahasa Indonesia */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
    content: "ID";
    color: #2196F3;
    font-size: 14px;
    text-align: center;
    line-height: 28px;
  }
  
  /* Memodifikasi tombol untuk bahasa Inggris */
  .slider:before {
    content: "EN";
    color: #2196F3;
    font-size: 14px;
    text-align: center;
    line-height: 28px;
  }

  .table-row-light {
    background-color: #ffffff;
  }
  .table-row-dark {
      background-color: #ff4e4e;
  }

  .page-header, .page-header-space {
    height: auto;
    margin: 20mm;
  }
  
  .page-footer, .page-footer-space {
    height: auto;
  }
  
  
  .page-footer {
    display: none;
  }
  .page-header {
    position: fixed;
    top: 0mm;
    width: 100%;
    margin: 10mm;
  }
  
  .page {
    page-break-after: avoid;
    margin: 8mm;
  }
  /* .total {
    display: none;   
  } */
  
  @media print {
     thead {display: table-header-group;} 
     tfoot {display: table-footer-group;}
     
     button {display: none;}
     
     body {margin: 0;}
     /* .total {
      display: block;
     } */
     .page-footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      display: initial;
      margin: 10mm;
    }
  }